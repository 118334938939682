.profile-pic{
    width: 80px;
    border-radius: 35px;
    background-color: #ebebeb;
    margin: 30px 0;
}
.featureImage{
    width: 100px;
    background-color: #ebebeb;
}
.subtitle{
    color: #eb1163;
    font-size: 18px;
    font-weight: 600;
}
.centerAlign{
    text-align: center;
    margin: auto;
    padding: 50px 0;
}
.profile-form{
    max-width: 70%;
    margin: auto;
}
.profile-form > .form-group{
    margin: 30px 0;
}
.live-nav a{color: black}
.live-nav a:hover{color: #eb1163;}


.active{color: #eb1163 !important;}