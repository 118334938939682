.bg-login{
    background-color: lightgray;
}
.login-screen{
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 3px double #ccc;
    border-radius: 25px;
    margin-top: 30px;
    box-shadow: 3px 7px 5px #ebebeb;
}
.login-title{
    padding: 20px;
}
.login-input{
    border-radius: 35px;
    border: none;
    background-color: #fff8fb;

}
.login-form-header{
    margin: auto;
    text-align: center;
}
.logo{
    width: 150px;
}
.login-button{
    background-color: #eb1163;
    width: 100%;
    border-radius: 35px;
    border: none;
}
.login-button:hover,.login-button:focus   {
    background-color: white;
    color: #eb1163;
    border-radius: 35px;
    border: 1px solid #eb1163;
    box-shadow: 3px 5px 7px #ccc;
}

.form-body{
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 30px 0px;
}